<template>
  <div class="index-container">
    <dataFilter ref="dataFilter" :filter="filter">
      <el-button type="primary" @click="handleQuery()">查询</el-button>
      <el-button type="warning" @click="handleExport()">导出</el-button>
    </dataFilter>
    <div class="tab-content">
      <el-table border size="mini" :data="datas" style="width: 100%">
        <el-table-column label="基本信息" show-overflow-tooltip align="center">
          <el-table-column
            prop="Check.Code"
            fixed="left"
            label="测试编号"
            :width="180"
          >
          </el-table-column>
          <el-table-column label="客户姓名" :min-width="80">
            <template slot-scope="scope">
              <div>
                {{ scope.row.Check.Member.RealName }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="Check.Member.Mobile"
            label="手机号"
            :width="120"
          >
          </el-table-column>
          <el-table-column prop="Check.Member.Sex" label="性别" :width="60">
          </el-table-column>
          <el-table-column label="报告日期" :min-width="120">
            <template slot-scope="scope">
              <el-tag type="success">{{
                format.dateFormat(scope.row.Check.ReportDate)
              }}</el-tag>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column label="得分" show-overflow-tooltip align="center">
          <el-table-column
            label="总分"
            prop="Score"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="关节活动度得分"
            prop="Score1"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="功能动作得分"
            prop="Score2"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
        </el-table-column>
        <el-table-column
          label="主动直腿上抬"
          show-overflow-tooltip
          align="center"
        >
          <el-table-column
            label="左选项"
            prop="LeftZdztstOption"
            show-overflow-tooltip
            align="center"
            width="150"
          >
            <template slot-scope="scope">
              {{
                format.kneeHurtZhiTuiShangTaiFormat(scope.row.LeftZdztstOption)
              }}
            </template>
          </el-table-column>
          <el-table-column
            label="左值"
            prop="LeftZdztst"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="右选项"
            prop="RightZdztstOption"
            show-overflow-tooltip
            width="150"
            align="center"
          >
            <template slot-scope="scope">
              {{
                format.kneeHurtZhiTuiShangTaiFormat(scope.row.RightZdztstOption)
              }}
            </template>
          </el-table-column>
          <el-table-column
            label="右值"
            prop="RightZdztst"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="得分"
            prop="TotalZdztst"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
        </el-table-column>
        <el-table-column
          label="俯卧髋关节主动伸展"
          show-overflow-tooltip
          align="center"
        >
          <el-table-column
            label="左选项"
            prop="LeftFwzdskOption"
            show-overflow-tooltip
            width="150"
            align="center"
          >
            <template slot-scope="scope">
              {{
                format.kneeHurtKuanGuanJieShenZhanFormat(
                  scope.row.LeftFwzdskOption
                )
              }}
            </template>
          </el-table-column>
          <el-table-column
            label="左值"
            prop="LeftFwzdsk"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="右选项"
            prop="RightFwzdskOption"
            show-overflow-tooltip
            width="150"
            align="center"
          >
            <template slot-scope="scope">
              {{
                format.kneeHurtKuanGuanJieShenZhanFormat(
                  scope.row.RightFwzdskOption
                )
              }}
            </template>
          </el-table-column>
          <el-table-column
            label="右值"
            prop="RightFwzdsk"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="得分"
            prop="TotalFwzdsk"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
        </el-table-column>
        <el-table-column
          label="俯卧膝关节主动屈曲"
          show-overflow-tooltip
          align="center"
        >
          <el-table-column
            label="左选项"
            prop="LeftFwxgjzdqqOption"
            show-overflow-tooltip
            align="center"
          >
            <template slot-scope="scope">
              {{
                format.kneeHurtXiGuanJieQuShenFormat(
                  scope.row.LeftFwxgjzdqqOption
                )
              }}
            </template>
          </el-table-column>
          <el-table-column
            label="左值"
            prop="LeftFwxgjzdqq"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="右选项"
            prop="RightFwxgjzdqqOption"
            show-overflow-tooltip
            align="center"
          >
            <template slot-scope="scope">
              {{
                format.kneeHurtXiGuanJieQuShenFormat(
                  scope.row.RightFwxgjzdqqOption
                )
              }}
            </template>
          </el-table-column>
          <el-table-column
            label="右值"
            prop="RightFwxgjzdqq"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="得分"
            prop="TotalFwxgjzdqq"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
        </el-table-column>
        <el-table-column
          label="俯卧屈膝髋关节内旋"
          show-overflow-tooltip
          align="center"
        >
          <el-table-column
            label="左选项"
            prop="LeftFwqxgjnxOption"
            show-overflow-tooltip
            align="center"
            width="150"
          >
            <template slot-scope="scope">
              {{
                format.kneeHurtKuanGuanJieNeiXuanFormat(
                  scope.row.LeftFwqxgjnxOption
                )
              }}
            </template>
          </el-table-column>
          <el-table-column
            label="左值"
            prop="LeftFwqxgjnx"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="右选项"
            prop="RightFwqxgjnxOption"
            show-overflow-tooltip
            align="center"
            width="150"
          >
            <template slot-scope="scope">
              {{
                format.kneeHurtKuanGuanJieNeiXuanFormat(
                  scope.row.RightFwqxgjnxOption
                )
              }}
            </template>
          </el-table-column>
          <el-table-column
            label="右值"
            prop="RightFwqxgjnx"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="得分"
            prop="TotalFwqxgjnx"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
        </el-table-column>
        <el-table-column
          label="踝关节灵活性"
          show-overflow-tooltip
          align="center"
        >
          <el-table-column
            label="左选项"
            prop="LeftHgjlhxOption"
            show-overflow-tooltip
            align="center"
            width="150"
          >
            <template slot-scope="scope">
              {{
                format.kneeHurtHuaiGuanJieLingHuoFormat(
                  scope.row.LeftHgjlhxOption
                )
              }}
            </template>
          </el-table-column>
          <el-table-column
            label="左值"
            prop="LeftHgjlhx"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="右选项"
            prop="RightHgjlhxOption"
            show-overflow-tooltip
            align="center"
            width="150"
          >
            <template slot-scope="scope">
              {{
                format.kneeHurtHuaiGuanJieLingHuoFormat(
                  scope.row.RightHgjlhxOption
                )
              }}
            </template>
          </el-table-column>
          <el-table-column
            label="右值"
            prop="RightHgjlhx"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="得分"
            prop="TotalHgjlhx"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
        </el-table-column>
        <el-table-column label="动作控制" show-overflow-tooltip align="center">
          <el-table-column
            label="左选项"
            prop="LeftBydtzlwdxOption"
            show-overflow-tooltip
            width="150"
            align="center"
          >
            <template slot-scope="scope">
              {{
                format.kneeHurtDanTuiZhanLiFormat(scope.row.LeftBydtzlwdxOption)
              }}
            </template>
          </el-table-column>
          <el-table-column
            label="左值"
            prop="LeftBydtzlwdx"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="右选项"
            prop="RightBydtzlwdxOption"
            show-overflow-tooltip
            align="center"
            width="150"
          >
            <template slot-scope="scope">
              {{
                format.kneeHurtDanTuiZhanLiFormat(
                  scope.row.RightBydtzlwdxOption
                )
              }}
            </template>
          </el-table-column>
          <el-table-column
            label="右值"
            prop="RightBydtzlwdx"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="得分"
            prop="TotalBydtzlwdx"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
        </el-table-column>

        <el-table-column label="单腿下蹲" show-overflow-tooltip align="center">
          <el-table-column
            label="左选项"
            prop="LeftGjbOptions"
            show-overflow-tooltip
            align="center"
            width="150"
          >
            <template slot-scope="scope">
              {{ format.kneeHurtGongJianBuFormat(scope.row.LeftGjbOptions) }}
            </template>
          </el-table-column>
          <el-table-column
            label="左分"
            prop="LeftGjb"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="右选项"
            prop="RightGjbOptions"
            show-overflow-tooltip
            align="center"
            width="150"
          >
            <template slot-scope="scope">
              {{ format.kneeHurtGongJianBuFormat(scope.row.RightGjbOptions) }}
            </template>
          </el-table-column>
          <el-table-column
            label="右分"
            prop="RightGjb"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="得分"
            prop="TotalGjb"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
        </el-table-column>
        <el-table-column
          label="髋关节铰链"
          show-overflow-tooltip
          align="center"
        >
          <el-table-column
            label="选项"
            prop="TotalKgjjlOptions"
            show-overflow-tooltip
            align="center"
            width="150"
          >
            <template slot-scope="scope">
              {{
                format.kneeHurtKuanGuanJieJiaoLianFormat(
                  scope.row.TotalKgjjlOptions
                )
              }}
            </template>
          </el-table-column>
          <el-table-column
            label="得分"
            prop="TotalKgjjl"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
        </el-table-column>
        <el-table-column label="深蹲" show-overflow-tooltip align="center">
          <el-table-column
            label="选项"
            prop="TotalSdOptions"
            show-overflow-tooltip
            align="center"
            width="150"
          >
            <template slot-scope="scope">
              {{ format.kneeHurtShenDunFormat(scope.row.TotalSdOptions) }}
            </template>
          </el-table-column>
          <el-table-column
            label="得分"
            prop="TotalSd"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
        </el-table-column>
        <el-table-column label="落地缓冲" show-overflow-tooltip align="center">
          <el-table-column
            label="选项"
            prop="TotalLdhcOptions"
            show-overflow-tooltip
            align="center"
            width="150"
          >
            <template slot-scope="scope">
              {{
                format.kneeHurtLuoDiHuanChongFormat(scope.row.TotalLdhcOptions)
              }}
            </template>
          </el-table-column>
          <el-table-column
            label="得分"
            prop="TotalLdhc"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
        </el-table-column>
      </el-table>
      <el-pagination
        class="pagination"
        background
        @size-change="pageSizeChange"
        @current-change="pageCurrentChange"
        :page-sizes="pageSizeList"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :current-page.sync="pageIndex"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
import dataFilter from "./components/dataFilter.vue";
import { exportData, getPageList } from "@/api/library/checkKneeHurt.js";
export default {
  components: { dataFilter },
  data() {
    return {
      loading: false,
      total: 0,
      pageIndex: 1,
      pageSizeList: [10, 20, 30, 50, 80],
      pageSize: 10,
      datas: [],
      filter: {},
    };
  },
  methods: {
    handleExport() {
      this.loadInstance = this.$loading({
        lock: true,
        text: "正在导出...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.filter = this.$refs.dataFilter.getfilter();
      exportData(this.filter)
        .then((res) => {
          this.loadInstance.close();
          if (res) {
            window.open(this.baseUrl + res);
          }
        })
        .catch((ex) => {
          this.msg.errorMsg("导出出错：" + ex);
          this.loadInstance.close();
        });
    },
    handleQuery() {
      let tempfilter = this.$refs.dataFilter.getfilter();
      this.filter = tempfilter;
      this.getData();
    },
    getData() {
      this.filter.pageIndex = this.pageIndex;
      this.filter.pageSize = this.pageSize;
      this.loading = true;
      getPageList(this.filter)
        .then((res) => {
          this.loading = false;
          if (res) {
            this.datas = res.Items;
            this.total = res.Total || res.Items.length;
          }
        })
        .catch((ex) => {
          this.loading = false;
          this.total = 0;
          this.datas = [];
        });
    },
    pageSizeChange(val) {
      var pageSize = val;
      this.pageIndex = 1;
      this.pageSize = parseInt(pageSize);
      this.$nextTick(() => this.getData());
    },
    pageCurrentChange(val) {
      this.pageIndex = val;
      this.getData();
    },
  },
  mounted() {
    this.handleQuery();
  },
};
</script>
<style lang="scss" scoped>
.index-container {
  .pagination {
    margin-top: 10px;
    text-align: right;
  }
  padding: 10px;
  .input {
    width: 150px;
  }
  .tabs-container {
    /deep/.el-tabs--border-card > .el-tabs__content {
      padding: 0;
      height: 1px;
    }
    .tab-content {
      margin-top: -2px;
      border: 1px solid #dcdfe6;
      border-top: 0;
      padding: 15px;
      background: #fff;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 0 6px 0 rgba(0, 0, 0, 0.04);
    }
  }
}
</style>