<template>
  <div class="customer-filter">
    <el-form size="small" ref="searchForm" :model="searchForm" :inline="true">
      <el-form-item label="门店">
        <shopSelector
          v-model="searchForm.tenant"
        ></shopSelector>
      </el-form-item>
      <el-form-item label="关键字">
        <el-input
          v-model="searchForm.search"
          placeholder="关键词搜索"
          clearable
        ></el-input>
      </el-form-item>
      <!-- <el-form-item label="来源">
        <enumSelector
          ref="statusSelector"
          :options="$config.checkSource"
          v-model="searchForm.source"
          style="width: 100px"
          size="small"
        />
      </el-form-item> -->
      <el-form-item>
        <dateOptionSelector
          :options="dateOptions"
          v-model="searchForm.dateTime"
          :multiple="true"
        />
      </el-form-item>
      <el-form-item>
        <slot></slot>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import dateOptionSelector from "@/components/dateOptionSelector";
import enumSelector from "@/components/enumSelector";
import shopSelector from '@/components/shopSelector'
export default {
  components: {
    enumSelector,
    dateOptionSelector,
    shopSelector,
  },
  props: {
    status: {
      default: "",
    },
  },
  watch: {
  },
  data() {
    return {
      dateOptions: [
        { value: "appoint", name: "预约时间" },
        { value: "completed", name: "报告时间" },
      ],
      searchForm: {
        search: "",
        dateTime: {
          dateType: "completed",
          date: [],
        },
      },
    };
  },
  methods: {
    getfilter() {
      let filters = {
        tenant:this.searchForm.tenant?this.searchForm.tenant.Key:"",
        search: this.searchForm.search,
        dateType: this.searchForm.dateTime.dateType,
        beginDate:
          this.searchForm.dateTime.date.length > 0
            ? this.searchForm.dateTime.date[0]
            : "",
        endDate:
          this.searchForm.dateTime.date.length > 1
            ? this.searchForm.dateTime.date[1]
            : "",
      };
      return filters;
    },
  },
  mounted() {},
};
</script>
<style lang='scss' scoped>
/deep/.el-form-item {
  margin-bottom: 10px;
}
</style>